<template>
  <div class="menus">
    <el-container>
      <el-header>
        <div class="titlename">
          <img
            src="@/assets/image/logao.png"
            alt=""
          />农产品集采平台后台管理系统

          <div @click="forceflush" class="versionitem">
            <div class="version">版本号【V{{version}}】</div>
            <div class="versionntn">更新</div>
          </div>
        </div>
        <div class="outtab">
          <el-popover placement="top" width="160" v-model="visible">
            <div class="tiimgs pointer">
              <img src="@/assets/image/my.png" alt="" />

              <div>{{ info.nickname }}</div>
              <div class="btn pointer" @click="latout">退出登录</div>
            </div>
            <div class="imgti pointer" slot="reference">
              <img src="@/assets/image/my.png" alt="" />

              <span>{{ info.nickname }}</span>
              <i class="el-icon-arrow-down"></i>
            </div>
            <!-- <el-button slot="reference">删除</el-button> -->
          </el-popover>
        </div>
      </el-header>
      <el-container>
        <el-aside>
          <el-menu
            :default-active="$route.path"
            class="el-menu-vertical-demo"
            router
            :unique-opened="true"
          >
            <div v-for="item in menuslist" :key="item.path">
              <el-submenu :index="item.path" v-if="item.children.length > 0">
                <template slot="title">
                  <!-- <i class="el-icon-location"></i> -->
                  <img :src="item.url" alt="" class="imgs" />
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item
                  :index="list.path"
                  v-for="list in item.children"
                  :key="list.path"
                  >{{ list.name }}</el-menu-item
                >
              </el-submenu>
              <div v-else>
                <el-menu-item :index="item.path">
                  <!-- <i class="el-icon-setting"></i> -->
                  <img :src="item.url" alt="" class="imgs" />
                  <span slot="title">{{ item.name }}</span>
                </el-menu-item>
              </div>
            </div>
          </el-menu>
        </el-aside>
        <el-main>
          <!-- <div class="tabs">
            <el-tag v-for="tag in tags" effect="dark" color="#21C2AC" :key="tag.name" closable>
              {{ tag.name }}
            </el-tag>
          </div> -->
          <div class="sbts">
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
const version = require('../../../package.json').version;
export default {
  name: "menus",
  components: {},
  data() {
    return {
      visible: false,
      version,
      list: [
        {
          path: "/meResume",
          name: "首页",
          children: [],
          url: require("@/assets/image/shou.png"),
        },
        {
          path: "/coreadmin",
          name: "中心账号管理",
          children: [],
          url: require("@/assets/image/huo.png"),
        },
        {
          path: "/changePassword",
          name: "修改密码",
          children: [],
          url: require("@/assets/image/66.png"),
        },
      ],
      menuslist: [
        // {
        //   path: "1",
        //   name: "权限管理",
        //   url: require("@/assets/image/quan.png"),
        //   children: [
        //     {
        //       path: "/adminlist",
        //       name: "管理员列表",
        //       children: [],
        //     },
        //     {
        //       path: "/Roleadmin",
        //       name: "角色管理",
        //       children: [],
        //     },
        //   ],
        // },
      ],
      info: "",
    };
  },
  methods: {
    forceflush(){
      window.location.reload(true);
    },
    latout() {
      sessionStorage.setItem("userInfo", "");
      sessionStorage.setItem("token", "");
      sessionStorage.removeItem("windowid");
      this.$message({
        message: "退出成功",
        type: "success",
      });
      this.$router.push({
        path: "/",
      });
      // this.$api.login.loginout({}).then((res) => {
      //   console.log(res, "查看");
      //   sessionStorage.setItem("userInfo", "");
      //   sessionStorage.setItem("token", "");

      // });
    },
  },
  created() {
    this.info = JSON.parse(sessionStorage.getItem("userInfo"));
    let arr = this.info.business_type_code.split(",");
    if (arr.includes("center")) {
      this.menuslist = this.list;
    } else {
      this.menuslist = [
        {
          path: "/meResume",
          name: "首页",
          children: [],
          url: require("@/assets/image/huo.png"),
        },
        {
          path: "/changePassword",
          name: "修改密码",
          children: [],
          url: require("@/assets/image/66.png"),
        },
      ];
    }
  },
};
</script>
<style lang="less" scoped>
.menus {
  height: 100vh;
  .el-header {
    display: flex;
    align-items: center;
    // justify-content: center;
    padding: 0;
    height: 70px !important;
    background: #222e44;
    .versionitem{
      display: flex;
      background: #2A3A55;
border-radius: 6px 0px 0px 6px;
border: 1px solid #394D6B;
box-sizing: border-box;
font-weight: 400;
font-size: 14px;
color: #FFFFFF;
margin-left: 20px;
.version{
  padding: 6px 9px;
  box-sizing: border-box;
}
.versionntn{
  background: #394D6B;
border-radius: 0px 6px 6px 0px;
padding: 6px 8px;
box-sizing: border-box;
}
    }
    .titlename {
      // width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      padding-left: 9px;
      box-sizing: border-box;
      // font-size: 20px;
      font-size: 22px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      img {
        width: 44px;
        height: 44px;
        margin-right: 9px;
      }
    }
    .title {
      box-sizing: border-box;
      padding: 0 20px;
      height: 100%;

      .list {
        color: #fff;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
      }
      .list:hover {
        background-color: #172033;
      }
    }

    .outtab {
      flex: 1;
      justify-content: flex-end;
      padding-right: 41px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #f2f2f2;
      img {
        width: 38px;
        height: 38px;
        margin-right: 8px;
      }
      .imgti {
        display: flex;
        align-items: center;
      }
    }
  }
  .el-aside {
    max-width: 200px;
    height: calc(100vh - 70px);
    background-color: #222e44 !important;
    .portrait {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 20px 0;

      div {
        width: 60px;
        height: 60px;
        background-color: #172033;
        border-radius: 50%;
        margin-bottom: 10px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }
    background: #fff;
    /deep/ .el-submenu__title {
      background: #222e44;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      font-size: 16px;
    }
    .el-menu-item {
      background: #222e44;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      font-size: 16px;
    }
    /deep/ .is-active {
      background: #172033;
      color: #fff;
    }
    .el-menu {
      border-right: 0;
      background: #222e44;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      font-size: 16px;
    }
    /deep/ .el-menu--inline {
      .el-menu-item {
        padding-left: 55px !important;
      }
    }
  }
  .el-main {
    height: calc(100vh - 70px);
    padding: 0;

    background: #f5f6f8;
    .tabs {
      height: 40px;
      background: #ffffff;
      margin: 6px;
      display: flex;
      align-items: center;
    }
    .sbts {
      height: calc(100vh - 70px);
      // height: calc(100vh - 122px);
    }
  }
  .imgs {
    width: 20px;
    height: 23px;
    margin-right: 15px;
    vertical-align: middle;
  }
}
.el-tag {
  margin: 0px 5px;
  border: 0;
}
.tiimgs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  img {
    width: 38px;
    height: 38px;
    margin-bottom: 4px;
  }
  .btn {
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #21c2ac;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #21c2ac;
    line-height: 13px;
    width: 94px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 13px;
  }
}
</style>
